<template>
	<div class="mainTem">
		<div class="buyerHeader"><el-page-header @back="goBack" :content="$t('i18nn_e5462a1be765de05')"></el-page-header></div>
		<div style="padding:20px; background-color: #FFFFFF;">
			<span>{{ $t('i18nn_f566f50ef680920c') }}</span>
			<el-radio-group v-model="orderType" :disabled="!hasEdit" size="medium" @change="changeOrderType">
				<el-radio-button v-for="item in selectOption.wh_work_order_type" :key="item.code" :label="item.code" v-if="30 == item.code||40 == item.code||60 == item.code">{{ $Utils.i18nKeyText(item,'codeText') }}</el-radio-button>
			</el-radio-group>
		</div>

		<div style="padding:10px; background-color: #FFFFFF;">
			<!-- <div v-if="10 == orderType || 20 == orderType"><questionTem @submitSuccess="goBack"></questionTem></div> -->
			<div v-if="30 == orderType">
				<!-- <TransshipmentTem @submitSuccess="goBack"></TransshipmentTem> -->
				<transferOrderTem @submitSuccess="goBack"></transferOrderTem>
			</div>
			<div v-else-if="40 == orderType"><dropShippingTem @submitSuccess="goBack"></dropShippingTem></div>
			<!-- <div v-else-if="50 == orderType"><ExchangeReturn @submitSuccess="goBack"></ExchangeReturn></div> -->
			<div v-else-if="60 == orderType"><PackingWarehousingTem @submitSuccess="goBack"></PackingWarehousingTem></div>
		</div>
	</div>
</template>
<script>
import {
		getDicData
	} from '@/axios/common.js';
// import questionTem from '@/components/StorageCenter/workOrder/workOrderTem/questionTem.vue';
// import TransshipmentTem from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentTem.vue';
import transferOrderTem from '@/components/StorageCenter/workOrder/workOrderTem/transferOrderTem.vue';
import dropShippingTem from '@/components/StorageCenter/workOrder/workOrderTem/dropShippingTem.vue';
import PackingWarehousingTem from '@/components/StorageCenter/workOrder/workOrderTem/PackingWarehousingTem.vue';
// import ExchangeReturn from '@/components/StorageCenter/workOrder/workOrderTem/ExchangeReturn.vue';
export default {

	components: {
		// questionTem,
		// TransshipmentTem,
		transferOrderTem,
		dropShippingTem,
		PackingWarehousingTem,
		// ExchangeReturn
	},
	data() {
		return {

			hasEdit: false,
			orderType:"40",
			selectOption: {
				wh_work_order_type: [],
			}
		};
	},
	activated() {
	  if(this.$route.query && this.$route.query.orderType){
	  	this.orderType = this.$route.query.orderType;
	  } else {
	  	// this.orderType = 10;
	  }
	  // this.$forceUpdate();
	  // this.getDicData();
	  
	  this.initData();
	},
	//创建时
	created() {
		// this.getPageData();
		// if(this.$route.query && this.$route.query.orderType){
		// 	this.orderType = this.$route.query.orderType;
		// } else {
		// 	// this.orderType = 10;
		// }
		// this.$forceUpdate();
		

		// this.initData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		//数据字典
		getDicData(['wh_work_order_type'],
			(data)=>{
				this.selectOption.wh_work_order_type = data['wh_work_order_type'];
		});
	},
	methods: {
		initData() {
			if(this.$route.query.id){
				this.hasEdit = false;
			} else {
				this.hasEdit = true;
			}
		},
		goBack(){
			this.$router.push({ name: 'ClientWorkOrderList' });
		},
		changeOrderType(v){
			console.log(v);
		},

		//查询数据字典
		// getDicData() {
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	@import url(@/assets/css/client_module.less);
</style>
